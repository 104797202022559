<script lang="ts">
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  export let score
  const SCORE_ITEMS = 5
</script>

<ul class="review-score">
  {#each { length: SCORE_ITEMS } as _, i}
    <li class="review-item" class:item-off="{i >= score}" class:item-on="{i < score}">
      <Icon size="small" icon="starFill" />
    </li>
  {/each}
</ul>

<style type="text/scss" lang="scss" scoped>
  .review-score {
    @apply flex;
  }

  .review-score > .review-item + .review-item {
    @apply ml-1;
  }

  .review-item {
    @apply w-6 h-6 text-white flex items-center justify-center;

    &.item-on {
      @apply bg-status-score bg-opacity-100;
    }

    &.item-off {
      @apply bg-gray-300;
    }
  }
</style>
